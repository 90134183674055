<template>
  <v-app>
    <!-- <v-row justify="center" align="center">
      <v-col class="px-0 py-0" cols="12" xl="5" lg="5" md="5" sm="12" xs="12"> -->
        <transition>
          <router-view></router-view>
        </transition>
      <!-- </v-col>
    </v-row> -->
  </v-app>

</template>
<script>
export default {
  name: "Layout",
  data() {
    return {
      overlay: false,
      items: [
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me 2" },
      ],
    };
  },
};
</script>
